import type { MetaFunction } from "@remix-run/node"
import { Link } from "@remix-run/react"
import type { RouteHandle } from "~/modules/common/type/RouteHandle"
import { Button } from "~/modules/common/ui/button"

export const meta: MetaFunction = () => {
  return [{ title: "Home" }]
}

export const handle: RouteHandle = {
  layout: {
    showFooter: true,
  },
  topnav: {
    transparent: true,
    showLinks: true,
    isFixed: true,
  },
}

export default function Index() {
  return (
    <section className="flex flex-col items-center justify-center w-full h-[calc(100vh-60px)] clip-home-image bg-[url(/images/hero-image-comp.jpg)] p-16">
      <div className="prose prose-invert flex flex-col gap-8 max-w-[unset]">
        <h1 className="text-6xl font-light m-0">Find out what your land is really worth.</h1>
        <h3 className="font-light m-0">
          Make better propety decisions with Trooper. Discover property development goldines, and avoid ticking
          timebombs.
        </h3>
        <Link to="/report" viewTransition className="w-fit">
          <Button variant={"outline"} color="secondary">
            Free Development Report
          </Button>
        </Link>
      </div>
    </section>
  )
}
